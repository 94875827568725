// {Settings.tsx}

import { GetVersionsResponse } from 'quanterix-protos/data_management/v1/data_management_messages';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import * as DataMgrApi from '../api/DataManagementApi';
import { appVersion, apiVersion, quilVersion } from '../ver';
import { GetRpckServiceVersion } from '../api/RpckApi';
// import useStyles from './styles';
// import TEST_ID from './constants';

export interface SettingsProps {
  // Props
}

// export default function Settings({}: SettingsProps) {
export default function Settings() {
  const [versions, setVersions] = useState(GetVersionsResponse.fromPartial({}));
  const [rpckVersion, setRpckVersion] = useState('TODO');
  // const { classes, cx } = useStyles();
  useEffect(() => {
    const fetchVersions = async () => {
      const fetchedVersions = await DataMgrApi.GetVersions();
      setVersions(fetchedVersions);
      const rpckVersionResponse = await GetRpckServiceVersion();
      setRpckVersion(rpckVersionResponse?.version || 'TODO');
    };

    fetchVersions();
    return () => {
      // Any cleanup code can go here
    };
  }, []);

  return (
    <Stack>
      <Typography>This is the parent page for Settings</Typography>
      <Typography>
        Software Module Versions provided for dev reference
      </Typography>
      <Box sx={{ height: '64px' }} />

      <Typography variant="h5">Module Versions</Typography>
      <Divider flexItem />
      {versions && (
        <Box width="500px">
          <Grid container columns={2}>
            <Grid item xs={1}>
              <Typography>Analysis Web App:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                variant="overline"
                aria-label="analysis-webapp-version"
              >
                {appVersion}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Data Management Service:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                variant="overline"
                aria-label="data-management-service-version"
              >
                {versions.dataManagementVersion}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Analysis Engine:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                variant="overline"
                aria-label="analysis-engine-version"
              >
                {versions.engineVersion}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Curve Fitting:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="overline" aria-label="curve-fitting-version">
                {versions.curveFitVersion}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Database API Service:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="overline" aria-label="database-api-version">
                {versions.runDataApiVersion}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Rpck Upload Service:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                variant="overline"
                aria-label="rpck-upload-service-version"
              >
                {rpckVersion}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Typography variant="h5">Dependency Versions</Typography>
      <Divider flexItem />
      {versions && (
        <Box width="500px">
          <Grid container columns={2}>
            <Grid item xs={1}>
              <Typography>GRPC API</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="overline" aria-label="grpc-api-version">
                {apiVersion}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>quil UX Library:</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="overline" aria-label="quil-ux-version">
                {quilVersion}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Stack>
  );
}
